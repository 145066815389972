import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./style.css";
import { useLocation } from "react-router-dom";
import img1 from "../../assets/img/blog-paving-stones1.jpeg";
import img2 from "../../assets/img/blog-chaferbeetle1.jpg";
import img3 from "../../assets/img/blog-bees3.png";
import img4 from "../../assets/img/blog-seasonal-garden.jpg";
import img5 from "../../assets/img/blog-lime1.jpg";
import img6 from "../../assets/img/projects/pavingStone/pavingStone3.jpg";
import img7 from "../../assets/img/blog-healthy-lawn.jpg";
import img8 from "../../assets/img/blog-watering.jpeg";
import img9 from "../../assets/img/blog-pollinators1.jpeg"
import img10 from "../../assets/img/fall.jpeg" 
import imgLogo from "../../assets/img/bur-hanBadge.png";
import imgcomingsoon from "../../assets/img/blog-coming-soon.png"
import imgcomingsoon1 from "../../assets/img/blog-coming-soon1.png"


export default function Blog() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [pageType, setPageType] = useState("");

  //Meta Tag changes
  useEffect(() => {
    const canonicalUrl = `https://bur-han.ca/blog`;
    document
      .querySelector('link[rel="canonical"]')
      .setAttribute("href", canonicalUrl);

    const newTitle = "Blog | BUR-HAN Garden & Lawn Care";
    setPageTitle(newTitle);
    document.title = newTitle;

    const newDescription =
      "Explore insightful articles on landscaping, lawn care, and outdoor living. Learn from the experts at BUR-HAN Garden & Lawn Care.";
    setPageDescription(newDescription);

    const newType = "article";
    setPageType(newType);

    const newKeywords = [
      "landscaping tips, lawn care tips, organic gardening, outdoor living ideas, garden maintenance, seasonal lawn care, sustainable landscaping, pest control tips, fertilizer guide, hardscaping ideas,natural fertilizers, improving soil health, bee-friendly gardens, chafer beetle control,spring garden preparation"
    ];
    setKeywords(newKeywords);
    document.keyword = newKeywords;

    // Update og:title meta tag
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", newTitle);

    // Update og:description meta tag
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", newDescription);

    // Update description meta tag
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", newDescription);

    // Update type meta tag
    document
      .querySelector('meta[property="og:type"]')
      .setAttribute("content", newType);

    // Update keywords meta tag
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", newKeywords.join(","));
  }, [location.pathname, setPageTitle, setPageDescription]);

  return (
    <div className="wrapper">
      <Header />
      <div className="blogHeader">
        <h1 data-aos="zoom-in"> Blog</h1>
      </div>
      <div className="blogMain" data-aos="fade-up">
        <div className="container">
          <section className="featured-posts">
            <div className="cardBlog-columns listfeaturedtag">

            <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/the-importance-of-a-fall-leaf-cleanup">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${img10})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                        The Importance Of A Fall Leaf Cleanup 
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">By The Bur-Han Team</span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>  
            <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/creating-a-pollinator-and-native-plant-garden">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${img9})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                        Creating a Pollinator and Native Plant Garden 
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">By The Bur-Han Team</span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>  
              <div className="cardBlog" data-aos="fade-up">
              <a href="/blog/the-essential-guide-to-lawn-fertilization">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${img5})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                          The Essential Guide to Lawn Fertilization

                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN logo"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">By The Bur-Han Team</span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

           


              <div className="cardBlog" data-aos="fade-up">
                <a href="/blog/responsible-lawn-watering-in-vancouver">
                  <div className="row">
                    <div className="col-5 wrapthumbnail">
                      <div
                        className="thumbnail"
                        style={{ backgroundImage: `url(${img8})` }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="cardBlog-block">
                        <h2 className="cardBlog-title mll">
                        Responsible Lawn Watering in Vancouver
                        </h2>
                        <div className="metafooter">
                          <div className="tagColor c1">landscaping</div>
                          <div className="wrapfooter">
                            <span className="meta-footer-thumb">
                              <img
                                className="author-thumb"
                                src={imgLogo}
                                alt="BUR-HAN spring"
                              />
                            </span>
                            <span className="author-meta">
                              <span className="post-name">By The Bur-Han Team</span>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>  

              
      
            </div>
          </section>

            <section className="recentBlog-posts">
            <div className="sectionBlog-title">
              <h2>
                <span data-aos="fade-up">More Blog Posts</span>
              </h2>
            </div>
             <div className="cardBlog-columns listrecent">
             
             <div className="cardBlog" data-aos="fade-left">
             <a href="/blog/chafer-beetle">
             <img className="img-fluid" src={img2} alt="" />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
The chafer Beetle                 <br/><br/>
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
              <a href="/blog/paving-stone-quality-and-value">
                <img className="img-fluid" src={img1} alt="" />     
                <div className="cardBlog-block">
                <h2 className="cardBlog-title"    >
                Paving Stone - Quality and Value
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c2">hardscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="cardBlog" data-aos="fade-left">
              <a href="/blog/saving-the-bees">
              <img className="img-fluid" src={img3} alt="" />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title">
                    Saving the Bees
                   <br/><br/>
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-left">
              <a href="">
                <img className="img-fluid" src={imgcomingsoon1} alt="" />     
                <div className="cardBlog-block ">
                <h2 className="cardBlog-title"    >
Coming Soon          <br/><br/>        </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c2">hardscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cardBlog" data-aos="fade-left">
              <a href="/blog/garden-maintenance-guide">
                  <img className="img-fluid" src={img4} alt="" />
                  <div className="cardBlog-block">
                    <h2 className="cardBlog-title" >
                    Garden Maintenance Guide - SPRING
                    </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c1">landscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
             
             
             
               <div className="cardBlog" data-aos="fade-left">
              <a href="">
                <img className="img-fluid" src={imgcomingsoon1} alt="" />     
                <div className="cardBlog-block">
                <h2 className="cardBlog-title"    >
Coming Soon        <br/><br/>         </h2>
                    <div className="metafooter">
                      <div className="tagColor1 c2">hardscaping</div>
                      <div className="wrapfooter">
                        <span className="meta-footer-thumb">
                          <img
                            className="author-thumb"
                            src={imgLogo}
                            alt="BUR-HAN"
                          />
                        </span>
                        <span className="author-meta">
                          <span className="post-name">By The Bur-Han Team</span>
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>      
        </div>
        <Footer />
      </div>
    </div>
  );
}
